import React, { useEffect, useMemo, useState } from "react";
import { createClient } from "contentful";
import { navigate } from "@reach/router";
import Event from "../templates/event";

// This page is for content editors to preview an event before publishing it in contentful
export default ({ location }) => {
  const [event, setEvent] = useState(null);
  const eventId = useMemo(
    () => location.search.slice(1, location.search.length),
    []
  );
  const description = event ? { raw: JSON.stringify(event.description) } : null;
  const image = event
    ? { file: { url: event.image.fields.file.url } }
    : { file: { url: "" } };

  useEffect(() => {
    if (process.env.GATSBY_NODE_ENV === "development") {
      const client = createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
        accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
        host: process.env.GATSBY_CONTENTFUL_PREVIEW_HOST
      });

      client
        .getEntry(eventId)
        .then(entry => {
          setEvent(entry.fields);
        })
        .catch(err => console.log("An error occurred.", err));
    } else {
      navigate("/404");
    }
  }, []);

  return event ? (
    <>
      <p>
        To simplify event previews, forms are ignored. If you have added a form
        to the event in contentful, it will not be displayed here nor will the
        buttons to access the form. (This text block is not part of the event)
      </p>
      <Event
        pageContext={{
          event: { ...event, description, form: null, image },
          events: [],
          // @ts-ignore
          content: {}
        }}
      />
    </>
  ) : (
    <span>Fetching event...</span>
  );
};
