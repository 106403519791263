import React from "react";
import { IRedirectBannerList } from "./types";
import RedirectBanner from "./RedirectBanner";
import "./redirectBanner.css";

const RedirectBannerList: React.FC<IRedirectBannerList> = ({ banners }) => (
  <div className="redirect-banner-list">
    {banners.map(banner => (
      <RedirectBanner key={banner.title} {...banner} />
    ))}
  </div>
);

export default RedirectBannerList;
